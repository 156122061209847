/* styles.css */

.animated-component2 {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.slide-out {
  opacity: 0;
  transform: translateY(-100%);
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}



@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


@keyframes slide-in2 {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.animated-componentslidein {
  opacity: 0;
  animation: slide-in 1s ease-in-out; /* Use animation property instead of transition */
}

.animated-componentslidein.visible {
  opacity: 1;
}
.animated-componentslidein2 {
  opacity: 0;
  animation: slide-in 1s ease-in-out; /* Use animation property instead of transition */
}

.animated-componentslidein2.visible {
  opacity: 1;
}
.animated-componentslideout {
  opacity: 0;
  animation: slide-out 1s ease-in-out; /* Use animation property instead of transition */
}

.animated-componentslideout.visible {
  opacity: 1;
}

@keyframes pulse {
  from, to {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
.animated-componentpulse {
  opacity: 0;
  animation: pulse 1s ease-in-out infinite;
}
.animated-componentpulse.visible {
  opacity: 1;
}

@keyframes rotate {
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
}
.animated-componentrotate {
  opacity: 0;
  animation: rotate 2s linear infinite;
}
.animated-componentrotate.visible {
  opacity: 1;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animated-componentbounce {
  opacity: 0;
  animation: bounce 1s ease-in-out;
}
.animated-componentbounce.visible {
  opacity: 1;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animated-componentfade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
}
.animated-componentfade-in.visible {
  opacity: 1;
}


.slide-down {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.slide-down.visible {
  max-height: 1000px; /* Adjust as needed */
}

.animated-component {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

